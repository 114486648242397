import "./Header.css";
// import HamburgerMenu from "../../Images/hamburger.png";
import Vahan from "../../Images/vahan-text.png";
import User from "../../Images/user.png";
import ApolloLogo from "../../Images/apollo-logo.png";
import { useEffect, useState } from "react";
import { Menu, Dropdown } from "antd";
import CustomDrawer from "../DrawerMenu/DrawerMenu";
import { useNavigate } from "react-router-dom";
import SOS from "../../Images/sos.png";

const Header = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  // const onHamburgerClick = () => {
  //   setOpen(true);
  // };
  console.log("open", open);

  const mobileMenu = (
    <Menu>
      <Menu.Item key="profile">
        <p className="dropdown-user-name">User</p>
      </Menu.Item>
      <Menu.Item key="orderHistory">
        <p className="dropdown-user-name">My Profile</p>
      </Menu.Item>
      <Menu.Item key="logout">
        <p className="dropdown-user-name">Logout</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="hamburger-section">
          <img
            className="apollo-logo"
            src={ApolloLogo}
            onClick={() => navigate("/dashboard")}
            alt="apollo-logo"
          />
          {isMobile ? (
            <img
              className="hamburger-menu"
              src={SOS}
              alt="sos"
              // onClick={onHamburgerClick}
            />
          ) : // <img
          //   className="hamburger-menu"
          //   src={HamburgerMenu}
          //   alt="hamburger-menu"
          //   onClick={onHamburgerClick}
          // />
          null}
        </div>
        <div>
          <img className="vahan-text" src={Vahan} alt="Vahan" />
        </div>
        <div className="user-container">
          {isMobile ? (
            <Dropdown overlay={mobileMenu} trigger={["click"]}>
              <a>
                <img className="user" src={User} alt="User" />
              </a>
            </Dropdown>
          ) : (
            <>
              <img className="user" src={User} alt="User" />
              <div className="user-details-container">
                <p className="user-name">DC User</p>
                <p className="logout-text">My Profile | Logout</p>
              </div>
            </>
          )}
        </div>
      </div>
      <CustomDrawer open={open} setOpen={setOpen} isMobile={isMobile} />
    </div>
  );
};

export default Header;
