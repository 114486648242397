import { ColorRing } from "react-loader-spinner";
import "./Loader.css";

const Loader = ({ visible, colors, height, width }) => {
  return (
    <div className="loader-container">
      <ColorRing
        visible={visible}
        height={height}
        width={width}
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        className="loader-container"
        colors={colors}
      />
    </div>
  );
};

export default Loader;
