import { Select } from "antd";
const DropDown = ({
  className,
  defaultValue,
  onChange,
  options,
  placeholder,
}) => {
  return (
    <div>
      <Select
        defaultValue={defaultValue}
        style={{
          width: "100%",
        }}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        className={className}
      />
    </div>
  );
};

export default DropDown;
