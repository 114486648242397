import { Drawer } from "antd";
import "./DrawerMenu.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DrawerMenu = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [adminLogin] = useState("driver");

  const onClose = () => {
    setOpen(false);
  };
  const showDrawer = (route) => {
    navigate(route);
    setOpen(false);
  };

  const drawerMenu = [
    {
      role: "dc",
      data: [
        {
          path: "/dashboard",
          label: "Dashboard",
        },
        {
          path: "/orders",
          label: "Orders",
        },
        {
          path: "/vehicle-master",
          label: "Vehicle Master",
        },
      ],
    },
    {
      role: "admin",
      data: [
        {
          path: "/dashboard",
          label: "Dashboard",
        },
        {
          path: "/order-listing",
          label: "Orders",
        },
        {
          path: "/stores",
          label: "Stores",
        },
        {
          path: "/dc-management",
          label: "DC Management",
        },
        {
          path: "/drivers",
          label: "Drivers",
        },
      ],
    },
    {
      role: "driver",
      data: [
        {
          path: "/store/dashboard",
          label: "Dashboard",
        },
        {
          path: "/store/orders",
          label: "Order Listing",
        },
        {
          path: "/driver/dashboard",
          label: "Driver",
        },
      ],
    },
  ];
  console.log(drawerMenu);

  return (
    <div>
      <Drawer open={open} title="Menu" onClose={onClose} placement="left">
        {drawerMenu?.map((item) =>
          item.role === adminLogin
            ? item.data?.map((item1, index) => (
                <div className="drawer-body">
                  <p
                    className="drawer-text"
                    onClick={() => showDrawer(`${item1.path}`)}
                    key={index}
                  >
                    {item1.label}
                  </p>
                </div>
              ))
            : null
        )}
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
