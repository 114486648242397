import "./App.css";
import { Outlet } from "react-router-dom";
import MainLayout from "./UIComponents/MainLayout";

function App() {
  return (
    <div className="website-container">
      <MainLayout>
        <Outlet />
      </MainLayout>
    </div>
  );
}

export default App;
