import App from "../App";
import { lazy } from "react";
import { Suspense } from "react";
import Loader from "../utils/Loader";
import { createBrowserRouter } from "react-router-dom";

const LoginPage = lazy(() => import("../Pages/Login/Login"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const OrderListing = lazy(() => import("../Pages/OrderListing/OrderListing"));
const VehicleMaster = lazy(() =>
  import("../Pages/VehicleMaster/VehicleMaster")
);
const Stores = lazy(() => import("../Pages/Stores/Stores"));
const DcManagement = lazy(() => import("../Pages/DcManagement/DcManagement"));
const Drivers = lazy(() => import("../Pages/Drivers/Drivers"));
const AddStore = lazy(() => import("../Pages/Stores/AddStore"));
const AddDC = lazy(() => import("../Pages/DcManagement/AddDC"));
const AddDriver = lazy(() => import("../Pages/Drivers/AddDriver"));

// Drivers
const DashboardPage = lazy(() => import("../Pages/Driver/Dashboard"));
const OrdersPage = lazy(() => import("../Pages/Driver/Orders"));

const SuspenseRoute = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="loader-routes">
          <Loader
            colors={["#000000", "#000000", "#000000", "#000000", "#000000"]}
            height={60}
            width={70}
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <SuspenseRoute>
            <LoginPage />
          </SuspenseRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <SuspenseRoute>
            <Dashboard />
          </SuspenseRoute>
        ),
      },
      {
        path: "/order-listing",
        element: (
          <SuspenseRoute>
            <OrderListing />
          </SuspenseRoute>
        ),
      },
      {
        path: "/vehicle-master",
        element: (
          <SuspenseRoute>
            <VehicleMaster />
          </SuspenseRoute>
        ),
      },
      {
        path: "/stores",
        element: (
          <SuspenseRoute>
            <Stores />
          </SuspenseRoute>
        ),
      },
      {
        path: "/dc-management",
        element: (
          <SuspenseRoute>
            <DcManagement />
          </SuspenseRoute>
        ),
      },
      {
        path: "/drivers",
        element: (
          <SuspenseRoute>
            <Drivers />
          </SuspenseRoute>
        ),
      },
      {
        path: "/add-store",
        element: (
          <SuspenseRoute>
            <AddStore />
          </SuspenseRoute>
        ),
      },
      {
        path: "/add-dc",
        element: (
          <SuspenseRoute>
            <AddDC />
          </SuspenseRoute>
        ),
      },
      {
        path: "/add-driver",
        element: (
          <SuspenseRoute>
            <AddDriver />
          </SuspenseRoute>
        ),
      },
      {
        path: "/store/dashboard",
        element: (
          <SuspenseRoute>
            <DashboardPage />
          </SuspenseRoute>
        ),
      },
      {
        path: "/store/orders",
        element: (
          <SuspenseRoute>
            <OrdersPage />
          </SuspenseRoute>
        ),
      },
      {
        path: "/driver/dashboard",
        element: (
          <SuspenseRoute>
            <DashboardPage />
          </SuspenseRoute>
        ),
      },
    ],
  },
]);
export default router;
