import { Tabs } from "antd";
import DashboardTabIcon from "../../Images/tab-dashboard.png";
import VehicleMasterTabIcon from "../../Images/tab-vehicle-master.png";
import "./NavBar.css";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DropDown from "../../UIComponents/DropDown/DropDown";
const { TabPane } = Tabs;

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [adminLogin] = useState("dc");
  const [activeKey, setActiveKey] = useState(getActiveKey(location.pathname));

  function getActiveKey(pathname) {
    if (pathname === "/dashboard") return "1";
    if (pathname === "/order-listing") return "2";
    if (pathname === "/vehicle-master") return "3";
    if (pathname === "/stores") return "6";
    if (pathname === "/dc-management") return "7";
    if (pathname === "/drivers") return "8";
    return null;
  }

  const onTabClick = (key) => {
    if (key === "1") {
      navigate("/dashboard");
    } else if (key === "2") {
      navigate("/order-listing");
    } else if (key === "3") {
      navigate("/vehicle-master");
    } else if (key === "6") {
      navigate("/stores");
    } else if (key === "7") {
      navigate("/dc-management");
    } else if (key === "8") {
      navigate("/drivers");
    }
  };

  useEffect(() => {
    setActiveKey(getActiveKey(location.pathname));
  }, [location.pathname]);

  return (
    <>
      {adminLogin === "dc" ? (
        <div className="dc-tab-container">
          <div>
            <Tabs activeKey={activeKey} onTabClick={onTabClick}>
              <TabPane
                tab={
                  <div className="navbar-section">
                    <img
                      src={DashboardTabIcon}
                      alt="dashboard-tab-icon"
                      className="navbar-img"
                    />
                    <span className="navbar-title">Dashboard</span>
                  </div>
                }
                key="1"
              ></TabPane>
              <TabPane
                tab={
                  <div className="navbar-section">
                    <img
                      src={DashboardTabIcon}
                      alt="dashboard-tab-icon"
                      className="navbar-img"
                    />

                    <span className="navbar-title">Orders</span>
                  </div>
                }
                key="2"
              ></TabPane>
              <TabPane
                tab={
                  <div className="navbar-section">
                    <img
                      src={VehicleMasterTabIcon}
                      alt="vehicle-master-tab-icon"
                      className="navbar-img"
                    />

                    <span className="navbar-title">Vehicle Master</span>
                  </div>
                }
                key="3"
              ></TabPane>
            </Tabs>
          </div>
          <div className="dc-dropdown-sec">
            <DropDown
              defaultValue="Select DC"
              className="select-dc-dropdown"
            ></DropDown>
          </div>
        </div>
      ) : adminLogin === "admin" ? (
        <Tabs activeKey={activeKey} onTabClick={onTabClick}>
          <TabPane
            tab={
              <div className="navbar-section">
                <img
                  src={DashboardTabIcon}
                  alt="dashboard-tab-icon"
                  className="navbar-img"
                />
                <span className="navbar-title">Dashboard</span>
              </div>
            }
            key="1"
          ></TabPane>
          <TabPane
            tab={
              <div className="navbar-section">
                <img
                  src={DashboardTabIcon}
                  alt="dashboard-tab-icon"
                  className="navbar-img"
                />

                <span className="navbar-title">Orders Listing</span>
              </div>
            }
            key="2"
          ></TabPane>
          <TabPane
            tab={
              <div className="navbar-section">
                <img
                  src={DashboardTabIcon}
                  alt="dashboard-tab-icon"
                  className="navbar-img"
                />

                <span className="navbar-title">Stores</span>
              </div>
            }
            key="6"
          ></TabPane>
          <TabPane
            tab={
              <div className="navbar-section">
                <img
                  src={DashboardTabIcon}
                  alt="dashboard-tab-icon"
                  className="navbar-img"
                />

                <span className="navbar-title">DC Management</span>
              </div>
            }
            key="7"
          ></TabPane>
          <TabPane
            tab={
              <div className="navbar-section">
                <img
                  src={DashboardTabIcon}
                  alt="dashboard-tab-icon"
                  className="navbar-img"
                />

                <span className="navbar-title">Drivers</span>
              </div>
            }
            key="8"
          ></TabPane>
        </Tabs>
      ) : null}
    </>
  );
};

export default NavBar;
